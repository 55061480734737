$white:#ffffff;
//$accent_color:#de3b46;
$accent_color:#3b84de;
$header_color:#172436;
$font_color:#6c7684;
$content_color:#f7f8f9;
$accent_color_gray:#DCDCDC;

$main_font:'Muli', sans-serif;

@mixin fill {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}

@mixin anim {
    transition: 0.3s all ease;
}

@mixin gradient_cover {
    background: rgba(2,2,2,0);
    background: -moz-linear-gradient(top, rgba(2,2,2,0) 40%, rgba(2,2,2,0.8) 100%);
    background: -webkit-gradient(left top, left bottom, color-stop(40%, rgba(2,2,2,0)), color-stop(100%, rgba(2,2,2,0.8)));
    background: -webkit-linear-gradient(top, rgba(2,2,2,0) 40%, rgba(2,2,2,0.8) 100%);
    background: -o-linear-gradient(top, rgba(2,2,2,0) 40%, rgba(2,2,2,0.8) 100%);
    background: -ms-linear-gradient(top, rgba(2,2,2,0) 40%, rgba(2,2,2,0.8) 100%);
    background: linear-gradient(to bottom, rgba(2,2,2,0) 40%, rgba(2,2,2,0.8) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#020202', endColorstr='#020202', GradientType=0 );
}

body{
    font-family: $main_font;
    h1,h2,h3,h4,h5,h6,p,ul,ol,a,p{
        margin: 0;
        font-weight: 400;
    }
    ul{
        list-style: none;
        padding: 0;
    }
    a{
        text-decoration: none;
        &:hover{
            text-decoration: none;
        }
    }
}
.boxes2{
    padding-bottom: 30px;
    text-align: center;
    ul{
        li{
            display: inline-block;
            vertical-align: top;
            width: 24%;
            padding-left: 30px;
            position: relative;
            @media only screen and (max-width: 991px) {
                width: 100%;
                padding-left: 0;
                padding-bottom: 30px;
                &:after{
                    display: none;
                }
            }
            &:last-child{
                padding-bottom: 0;
                &:after{
                    display: none;
                }
            }
            &:after{
                content: '';
                position: absolute;
                top: 0;
                left: 100%;
                height: 100%;
                width: 15px;
                background-repeat: no-repeat;
                background-position: center;
                background-size: 100% auto;
                background-image: url('./../img/home_arrow_right.png');
            }
            div{
                &.icon{
                    width: 70px;
                    height: 0;
                    padding-bottom: 70px;
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-position: center;
                    margin: 0 auto 15px auto;
                }
                &.desc{
                    text-align: center;
                    padding: 0 20px;
                    div.default_font{
                        color: #272437;
                        font-size: 17px;
                        line-height: 27px
                    }
                }
            }
        }
    }
}
.counts_box{
    padding: 11px 0;
    .count_box{
        width: 100%;
        text-align: center;
        font-size: 14px;
        line-height: 24px;
        margin-bottom: 5px;
        @media only screen and (max-width: 1500px) {
            font-size: 11px;
            line-height: 21px;
        }
        > li{
            font-weight: 700;
            width: 100%;
            font-size: 16px;
            p{
                font-weight: inherit;
                text-transform: uppercase;
                
            }
            &.counter{
                display: block;
                position: relative;
                font-weight: 700;
                color: #000;
                margin-bottom: 10px;
                div{
                    font-size: 44px;
                    line-height: 54px;
                    font-weight: 800;
                }
                @media only screen and (max-width: 1500px) {
                    font-size: 15px;
                    line-height: 15px;
                }
            }
        }
    }

       
}


.page{
    &.mourning{
        -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
        filter: grayscale(100%);
    }
    overflow: hidden;
    &.contrast{
        .boxes2{
            ul{
                li{
                    &:after{
                        display: none;
                        padding-right: 0;
                    }
                }
            }
            .icon{
                display: none;
            }
        }
        div.default_font{
            img{
                display: none;
            }
        }
        section.news_details {
            div.details_image{
                background-image: none !important;
            }
        }
        .bip_search{
            background: #2e2e2d;
            .input{
                color: yellow;
                background: #000000;
            }
            .submit{
                color: yellow;
                background: #a50b15;
                border: 1px solid #a50b15;
                &:hover{
                    
                    background: transparent;
                }
            }
            .chosen-container-single {
                .chosen-search {
                    input[type="text"]{
                        border: 1px solid #a50b15;
                        color: yellow;
                    }
                }
            }
            .chosen-container {
                .chosen-single{
                    background: #000000;
                }
            }
            .chosen-drop{
                background: #000000;
            }
            .chosen-results{
                li{
                    &.highlighted{
                        background: #a50b15;
                    }
                    &.no-results{
                        background: #a50b15;;
                        color: yellow;
                    }
                }
            }
            .chosen-single{
                span{
                    text-align: left;
                    padding-top: 10px;
                    color: yellow;
                }
            }
        }
        .classfields {
            div {
                .news_list {
                    ul {
                        li{
                            &:after{
                                background: #2e2e2d;
                            }
                        }
                    }
                }
            }
        }
        .last_news_header{
            background: #a50b15 !important;
        }
        .more_btn{
            background: #a50b15;
        }
        .default_calendar {
            div.header{
                background: #a50b15;
            }
        }
        .default_calendar {
            .day_event{
                background: #a50b15 !important;
            }
            .day_event_past{
                background: #D3D3D3 !important;
            }
            div.content {
                div.table {
                    > div {
                        &.tr{
                            &:first-child{
                                color: yellow !important;
                                div{
                                    color: yellow !important;
                                }
                            }
                        }
                        > div{
                            color: yellow !important;
                        }
                    }
                }
            }
        }
        h1,h2,h3,h4,h5,h6,p,a,li{
            color: yellow !important;
        }
        strong{
            color: yellow !important;
        }
        aside.side_menu {
            div.box_menu {
                header{
                    background: #a50b15;
                }
            }
        }
        aside.side_menu {
            div.box_menu {
                nav.box_menu_list {
                    ul {
                        li {
                            a.on{
                                background: #a50b15;
                            }
                        }
                    }
                }
            }
        }
        header.header{
            div.main_header {
                .header_top {
                    > ul{
                        > li.search {
                            form {
                                input {
                                    background-color: yellow;
                                    color: $accent_color;
                                }
                                ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                                    color: $accent_color;
                                  }
                                  ::-moz-placeholder { /* Firefox 19+ */
                                    color: $accent_color;
                                  }
                                  :-ms-input-placeholder { /* IE 10+ */
                                    color: $accent_color;
                                  }
                                  :-moz-placeholder { /* Firefox 18- */
                                    color: $accent_color;
                                  }
                            }
                        }
                    }
                }
            }
        }
        header.header, footer.footer, section.summary{
            background: #2e2e2d;
        }
        main.content, section.top{
            background: #000000;
        }
        .image, .newsletter, .slider > ul > li, li.orange, li.blue, li.purple{
            background:#2e2e2d !important; 
        }
        aside.side_menu {
            div.box_menu {
                nav.box_menu_list{
                    background: #2e2e2d;
                    > ul{
                        > li{
                            a, p{
                                &:hover{
                                    background: #a50b15;
                                }
                                &.open{
                                    background: #a50b15;
                                }
                            }
                        }
                    }
                }
            }
        }
        section.summary{
            div.hidden_changes{
                ul{
                    li {
                        .change_table{
                            background:#000000;
                        }
                    }
                }
            }
            div.summary_top {
                .buttons{
                    > li{
                        a{
                            background: #a50b15;
                        }
                    }
                }
            }
        }
        section.download {
            div.files {
                ul {
                    li {
                        a{
                            background: #a50b15;
                        }
                    }
                }
            }
        }
        .news_and_events {
            div {
                .news_list {
                    ul {
                        li{
                            &:after{
                                background: #2e2e2d;
                            }
                            &:hover{
                                &:after{
                                    background: $accent_color;
                                    border-bottom: none;
                                }
                            }
                        }
                    }
                }
            }
        }
        section.home_top {
            div {
                .news_list{
                    &.last_news_list{
                        ul {
                            li{
                                &:hover{
                                    &:after{
                                        background: $accent_color;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.more_btn{
    font-weight: 700;
    font-size: 12.5px;
    color: $white;
    padding: 13px 31px;
    border-radius: 10px;
    background: $accent_color;
    display: inline-block;
    @include anim;
    text-decoration: none;
    &:hover{
        text-decoration: none;
        opacity: 0.85;
        color: $white;
    }
}

.vertical_align{
    @include fill;
    > div{
        display: table;
        height: 100%;
        width: 100%;
        > div{
            display: table-cell;
            vertical-align: middle;
            height: 100%;
        }
    }
}

.default_font{
    font-weight: 600;
    font-size: 12.5px;
    line-height: 20px;
    //color: $font_color;
    color: #000000;
    h1{
        font-size: 35px;
        line-height: 45px;
    }
    h2{
        font-size: 25px;
        line-height: 37px;
    }
    h3{
        font-size: 18px;
        line-height: 24px;
    }
    h4{
        font-size: 16px;
        line-height: 26px;
    }
    h5{
        font-size: 14.5px;
        line-height: 24px;
    }
    h6{
        font-size: 13px;
        line-height: 23px;
    }
    ul{
        padding-left: 18px;
        list-style: disc;
        li{
            margin-bottom: 15px;
        }
    }
    ol{
        padding-left: 18px;
    }
    p{
        font-weight: inherit;
    }
    h1,h2,h3,h4,h5,h6,p,ul,ol,a{
        margin-bottom: 15px;
        &:last-child{
            margin-bottom: 0;
        }
    }
    h1,h2,h3,h4,h5,h6{
        color: $header_color;
    }
    strong{
        font-weight: 800;
        color: $header_color;
    }
    a{
        font-weight: 600;
        color: $accent_color;
    }
}

header.header{
    padding-top: 20px;
    background: url('./../img/header_bar.jpg') center top no-repeat;
    div.logo_box{
        position: relative;
        @media only screen and (max-width: 1200px) {
            img{
                max-width: 200px;
            }
        }
        @media only screen and (max-width: 991px) {
            img{
                max-width: 100px;
                height: auto;
            }
        }
        div.rwd_btn{
            width: 30px;
            position: absolute;
            top: 10px;
            right: 0;
            display: none;
            @media only screen and (max-width: 991px) {
                display: block;
            }
            > div{
                width: 100%;
                height: 3px;
                margin-bottom: 3px;
                background: $accent_color;
                &:last-child{
                    margin-bottom: 0;
                }
            }
        }
        .bip{
            margin-right: 35px;
            @media only screen and (max-width: 1500px) {
                margin-right: 15px;
            }
            @media only screen and (max-width: 991px) {
                img{
                    max-width: 30px;
                    height: auto;
                }
            }
        }
    }
    div.main_header{
        border-bottom:1px solid #e6ecf1;
        .header_top{
            padding-top: 9px;
            > ul{
                text-align: right;
                > li{
                    display: inline-block;
                    vertical-align: middle;
                    .social_media{
                        @media only screen and (max-width: 991px) {
                            padding-left: 15px;
                            margin-bottom: 15px;
                        }
                        > ul{
                            > li{
                                display: inline-block;
                                vertical-align: top;
                                margin-right: 15px;
                                @media only screen and (max-width: 991px) {
                                    &:last-child{
                                        margin-right: 0;
                                    }
                                }
                                img{
                                    width: 20px;
                                    height: auto;
                                }
                            }
                        }
                    }
                    &.logotypes{
                        margin-right: 63px;
                        @media only screen and (max-width: 1500px) {
                            margin-right: 15px;
                        }
                        @media only screen and (max-width: 1200px) {
                            margin-right: 15px;
                        }
                        @media only screen and (max-width: 991px) {
                            margin-right: 0;
                            margin-bottom: 15px;
                            width: 100%;
                        }
                        > ul{
                            > li{
                                margin-right: 30px;
                                &:last-child{
                                    margin-right: 0;
                                }
                            }
                        }
                    }
                    &.acces{
                        @media only screen and (max-width: 991px) {
                            margin-bottom: 15px;
                        }
                        > ul{
                            > li{
                                &.langs{
                                    margin-right: 25px;
                                    @media only screen and (max-width: 991px) {
                                        margin-left: 15px;
                                        margin-right: 0;
                                    }
                                    ul{
                                        li{
                                            display: inline-block;
                                            vertical-align: top;
                                            margin-right: 10px;
                                            @media only screen and (max-width: 991px) {
                                                margin-bottom: 0px;
                                            }
                                            &:last-child{
                                                margin-right: 0;
                                            }
                                            .on{
                                                a{
                                                    color: $accent_color;
                                                }
                                            }
                                            a{
                                                font-size: 15px;
                                                line-height: 15px;
                                                color:$font_color;
                                                font-weight: 700;
                                                @include anim;
                                                &:hover{
                                                    color: $accent_color;
                                                }
                                            }

                                            &.active {
                                                a {
                                                    color: $accent_color;
                                                }
                                            }
                                        }
                                    }
                                }
                                &.blind{
                                    cursor: pointer;
                                    margin-right: 25px;
                                    @media only screen and (max-width: 991px) {
                                        margin-left: 15px;
                                        margin-bottom: 0;
                                        margin-right:0;
                                    }
                                }
                                &.fonts{
                                    color: $header_color;
                                    margin-right: 25px;
                                    @media only screen and (max-width: 1500px) {
                                        margin-right: 15px;
                                    }
                                    @media only screen and (max-width: 991px) {
                                        display: none;
                                    }
                                    > ul{
                                        > li{
                                            margin-right: 3px;
                                            display: inline-block;
                                            vertical-align: bottom;
                                            font-weight: 600;
                                            cursor: pointer;
                                            &:last-child{
                                                margin-right: 0;
                                            }
                                            &.font_small{
                                                font-size: 10px;
                                                line-height: 11px;
                                            }
                                            &.font_medium{
                                                font-size: 14px;
                                                line-height: 14px;
                                            }
                                            &.font_large{
                                                font-size: 20px;
                                                line-height: 19px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    &.search{
                        @media only screen and (max-width: 991px) {
                            width: 100%;
                            margin-bottom: 20px;
                        }
                        form{
                            border-radius: 5px;
                            overflow: hidden;
                            input{
                                height: 45px;;
                                vertical-align: top;
                                margin: 0;
                                border: none;
                                background-color: #e6ecf1;
                                outline: none;
                                &.input_search{
                                    //width: 240px;
                                    width: 0px;
                                    color: $font_color;
                                    font-size: 12px;
                                    font-weight: 600;
                                    //padding: 0 20px;
                                    @media only screen and (max-width: 1500px) {
                                        width: 150px;
                                    }
                                    @media only screen and (max-width: 1200px) {
                                        width: 100px;
                                    }
                                    @media only screen and (max-width: 991px) {
                                        width: 80%;
                                        padding:0 20px;
                                    }
                                }
                                &.submit_search{
                                    display:none;
                                    width: 52px;
                                    padding: 0;
                                    background-repeat: no-repeat;
                                    background-position: center;
                                    background-image: url('./../img/search_icon.png');
                                    @media only screen and (max-width: 991px) {
                                        width: 19%;
                                        display:inline-block;
                                        vertical-align:top;
                                    }
                                }
                            }
                            div.submit_search{
                                background-color: #e6ecf1;
                                display: inline-block;
                                vertical-align: top;
                                cursor: pointer;
                                height: 45px;
                                width: 52px;
                                padding: 0;
                                background-repeat: no-repeat;
                                background-position: center;
                                background-image: url('./../img/search_icon.png');
                                @media only screen and (max-width: 991px) {
                                    width: 19%;
                                    display:none;
                                }
                            }
                        }
                    }
                    > ul{
                        > li{
                            display: inline-block;
                            vertical-align: middle;
                        }
                    }
                }
            }
        }
        .header_bottom{
            div.menu_box{
                padding: 30px 0;
                @media only screen and (max-width: 991px) {
                    padding: 15px 0;
                    display: none;
                }
                nav.menu{
                    text-align: right;
                    > ul{
                        > li{
                            display: inline-block;
                            vertical-align: top;
                            margin-right: 87px;
                            @media only screen and (max-width: 1500px) {
                                margin-right: 43px;
                            }
                            @media only screen and (max-width: 1200px) {
                                margin-right: 15px;
                            }
                            @media only screen and (max-width: 991px) {
                                margin-right: 0;
                                width: 100%;
                                text-align: center;
                                margin-bottom: 15px;
                                &:last-child{
                                    margin-bottom: 0;
                                }
                            }
                            .on{
                                a{
                                    font-weight: 800;
                                    &:after{
                                        width: 100%;
                                    }
                                }
                            }
                            &:last-child{
                                margin-right: 0;
                            }
                            a{
                                font-size: 14.5px;
                                line-height: 14.5px;
                                font-weight: 700;
                                color: $header_color;
                                display: inline-block;
                                position: relative;
                                @media only screen and (max-width: 1200px) {
                                    font-size: 13px;
                                    line-height: 13px;
                                }
                                &:hover{
                                    &:after{
                                        width: 100%;
                                    }
                                }
                                &:after{
                                    content:'';
                                    border-bottom: 2px solid $accent_color;
                                    position: absolute;
                                    top: 100%;
                                    left: 0;
                                    width: 0;
                                    @include anim;
                                }

                            }
                        }
                    }
                }
            }
        }
    }
    div.sub_menu{
        padding: 20px 0;
        @media only screen and (max-width: 991px) {
            padding: 15px 0;
            display: none;
        }
        nav.menu2{
            text-align: right;
            > ul{
                > li{
                    display: inline-block;
                    vertical-align: top;
                    margin-right: 80px;
                    @media only screen and (max-width: 1500px) {
                        margin-right: 40px;
                    }
                    @media only screen and (max-width: 1200px) {
                        margin-right: 15px;
                    }
                    @media only screen and (max-width: 991px) {
                        margin-right: 0;
                        margin-bottom: 15px;
                        width: 100%;
                        text-align: center;
                        &:last-child{
                            margin-bottom: 0;
                        }
                    }
                    &:last-child{
                        margin-right: 0;
                    }
                    .on{
                        a{
                            font-weight: 800;
                            &:after{
                                width: 100%;
                            }
                        }
                    }
                    a{
                        font-size: 13.5px;
                        line-height: 13.5px;
                        font-weight: 700;
                        color: #7e8894;
                        display: inline-block;
                        position: relative;
                        @media only screen and (max-width: 1200px) {
                            font-size: 12px;
                            line-height: 12px;
                        }
                        &:hover{
                            &:after{
                                width: 100%;
                            }
                        }
                        &:after{
                            content:'';
                            border-bottom: 2px solid $accent_color;
                            position: absolute;
                            top: 100%;
                            left: 0;
                            width: 0;
                            @include anim;
                        }
                    }
                }
            }
        }
    }
}

main.content{
    padding: 10px 0 30px 0;
    background: $content_color;
    @media only screen and (max-width: 991px) {
        padding: 30px 0;
    }
}

footer.footer{
    .social_media{
        padding-top:30px;
         > ul{
             > li{
                 display:inline-block;
                 vertical-align:top;
                 margin-right:25px;
                 img{
                     width:35px;
                     height:auto;
                 }
                 &:last-child{
                     margin-right:0;
                 }
             }
         }
    }
    @media only screen and (max-width: 991px) {
        text-align: center;
    }
    div.footer_top{
        border-bottom: 1px solid #dce2e8;
        padding: 45px 0;
        @media only screen and (max-width: 991px) {
            padding: 30px 0;
        }
        div{
            &.menu_box{
                p{
                    font-size: 16.5px;
                    line-height: 20px;
                    font-weight: 700;
                    color: $header_color;
                    margin-bottom: 20px;
                }
                nav{
                    ul{
                        @media only screen and (max-width: 991px) {
                            margin-bottom: 20px;
                        }
                        li{
                            a{
                                font-size: 13.5px;
                                line-height: 30px;
                                color: $font_color;
                                @include anim;
                                &:hover{
                                    padding-left: 7px;
                                }
                            }
                        }
                    }
                }
            }
            &.newsletter{
                border-radius: 5px;
                width: 100%;
                height: 0;
                padding-bottom: 100px;
                background-repeat: no-repeat;
                background-position: center;
                background-size: cover;
                position: relative;
                font-size: 16px;
                font-weight: 700;
                line-height: 25px;
                color: $white;
                p{
                    font-weight: inherit;
                    padding: 0 40px;
                }
            }
        }
    }
    div.footer_bottom{
        padding-top: 35px;
        padding-bottom: 35px;
        div{
            &.copyright{
                > div{
                    margin-bottom: 20px;
                }
                > a{
                    display: inline-block;
                    vertical-align: top;
                    margin-right: 27px;
                    font-size: 11.5px;
                    line-height: 11.5px;
                    color: $font_color;
                    &:last-child{
                        margin-right: 0;
                    }
                }
            }
            &.copyright, &.devs{
                color: $font_color;
                font-size: 13.5px;
                line-height: 13.5px;
            }
            &.devs{
                text-align: right;
                @media only screen and (max-width: 991px) {
                    text-align: center;
                    margin-top: 15px;
                }
                ul{
                    li{
                        display: inline-block;
                        vertical-align: middle;
                        &:last-child{
                            margin-left: 15px;
                        }
                    }
                }
            }
        }
    }
}

section.home_top{
    margin-bottom: 40px;
    @media only screen and (max-width: 991px) {
        margin-bottom: 30px;
    }
}

section.home_top, .news_and_events, .classfields{
    div{
        &.slider{
            width: 100%;
            height: 500px;
            position: relative;
            @media only screen and (max-width: 1200px) {
                margin-bottom: 30px;
            }
            @media only screen and (max-width: 991px) {
                height: 350px;
            }
            > ul{
                @include fill;
                > li{
                    @include fill;
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: cover;
                    .slider_content{
                        width: 100%;
                        position: absolute;
                        left: 0;
                        bottom: 50px;
                        z-index: 2;
                        @media only screen and (max-width: 991px) {
                            text-align: center;
                            bottom: 40px;
                        }
                        > div{
                            padding: 0 30px;
                        }
                        header{
                            margin-bottom: 20px;
                            font-size: 13px;
                            line-height: 22px;
                            color: $white;
                            @media only screen and (max-width: 991px) {
                                p{
                                    display: none;
                                }
                            }
                            h1{
                                color: $white;
                                font-size: 25px;
                                line-height: 40px;
                                font-weight: 700;
                                margin-bottom: 10px;
                                &:last-child{
                                    margin-bottom: 0;
                                }
                            }
                        }
                    }
                    .cover{
                        @include gradient_cover;
                        @include fill;
                    }
                }
            }
            div.slider_dots{
                width: 100%;
                text-align: right;
                position: absolute;
                right: 0;
                bottom: 29px;
                @media only screen and (max-width: 991px) {
                    bottom: 15px;
                    text-align: center;
                }
                > ul{
                    margin-right: 30px;
                    @media only screen and (max-width: 991px) {
                        margin-right: 0;
                    }
                    li{
                        position: relative;
                        z-index: 3;
                        display: inline-block;
                        vertical-align: top;
                        width: 12px;
                        height: 0;
                        padding-bottom: 12px;
                        border-radius: 50%;
                        background: $white;
                        margin-right: 16px;
                        cursor: pointer;
                        @include anim;
                        &.on{
                            background: $accent_color;
                        }
                        &:last-child{
                            margin-right: 0;
                        }
                    }
                }
            }
        }
        &.last_news{
            header.last_news_header{
                background: $accent_color;
                padding: 16px 20px;
                border-radius: 7px;
                border-bottom: 3px solid #e4e5e5;
                @media only screen and (max-width: 991px) {
                    text-align: center;
                }
                div.default_font{
                    color: $white;
                }
                h2{
                    font-size: 14.5px;
                    line-height: 14.5px;
                    color: $white;
                    font-weight: 700;
                    @media only screen and (max-width: 991px) {
                        margin-bottom: 10px;
                    }
                }
                a{
                    font-size:12.5px;
                    line-height: 12.5px;
                    text-decoration: underline;
                    @include anim;
                    color: rgba(255,255,255,0.7);
                    &:hover{
                        color: rgba(255,255,255,1);
                    }
                }
            }
        }
        .news_list{

            &.no_photo {
                article > div.description {
                    width:100%!important;
                }
            }

            article{
                > div{
                    display: inline-block;
                    vertical-align: top;
                    &.image{
                        width: 255px;
                        height: 150px;
                        background-repeat: no-repeat;
                        background-position: center;
                        background-size: contain;
                        position: relative;
                        div.cover{
                            @include fill;
                            @include anim;
                            background: $accent_color;
                            opacity: 0;
                            visibility: hidden;
                        }
                        @media only screen and (max-width: 991px) {
                            width: 100%;
                            margin-bottom: 15px;
                            height: 300px;
                        }
                    }
                    &.description{
                        width: calc(100% - 260px);
                        padding-left: 30px;
                        @media only screen and (max-width: 991px) {
                            width: 100%;
                            padding-left: 0;
                        }
                    }
                }
            }
            &.last_news_list{
                ul{
                    padding-top: 0;
                    li{
                        min-height: 150px;
                        border-bottom: 1px solid #dee3e9;
                        margin-bottom: 0;
                        padding:15px 10px 0 10px;
                        @media only screen and (max-width: 1200px) {
                            height: auto;
                            padding-bottom: 15px;
                        }
                        &:after{
                            content: '';
                            height: 100%;
                            top: 0;
                            left: 0;
                            width: 100%;
                            background: $white;
                            opacity: 0;
                            visibility: hidden;
                            position: absolute;
                            @include anim;
                            z-index: -1;
                            border-bottom: 1px solid #dee3e9;
                            border-radius: 10px;
                        }
                        &:hover{
                            &:after{
                                visibility: visible;
                                opacity: 1;
                            }
                        }
                        header{

                            h3{
                                font-weight: 700;
                                font-size: 14.5px;
                                line-height: 22px;
                            }
                        }
                        div.desc{
                            div.default_font{
                                font-size: 12px;
                            }
                        }
                    }
                }
            }
            ul{
                padding-top: 30px;
                li{
                    margin-bottom: 50px;
                    @include anim;
                    position: relative;
                    z-index: 1;
                    padding: 10px;
                    @media only screen and (max-width: 1500px) {
                        margin-bottom: 25px;
                    }
                    .desc{
                        div.defailt_font{
                            @include anim;
                        }
                    }
                    &:hover{
                        header{
                            color: $white;
                            div.date{
                                color: $white;
                            }
                            div.no{
                                color: $white;
                            }
                            div.identify{
                                color: $white;
                            }
                        }
                        div.desc{
                            div.default_font{
                                color: $white;
                            }
                        }
                        &:after{
                            background: $accent_color;
                        }
                        div.image{
                            div.cover{
                                visibility: visible;
                                opacity: 0.5;
                            }
                        }
                    }
                    &:after{
                        content: '';
                        @include fill;
                        background: #ffffff;
                        //opacity: 0;
                        //visibility: hidden;
                        position: absolute;
                        -webkit-transition: 0.3s all ease;
                        transition: 0.3s all ease;
                        z-index: -1;
                        border-bottom: 1px solid #dee3e9;
                        border-radius: 10px;
                    }
                    header{

                        color: $header_color;
                        //@include anim; 
                        margin-bottom: 10px;
                        h3{
                            font-size: 18px;
                            line-height: 27px;
                            font-weight: 800;
                        }
                        > p{
                            font-weight: inherit;
                        }
                        div.date{
                            font-size: 11px;
                            color: $font_color;
                            font-weight: 600;
                            padding-bottom: 10px;
                            //@include anim;
                            p{
                                font-weight: inherit;
                            }
                        }

                        div.no{
                            font-size: 12px;
                            color: $font_color;
                            font-weight: 600;
                            padding-bottom: 10px;
                            text-transform: uppercase;
                            //@include anim;
                            p{
                                font-weight: inherit;
                            }
                        }

                        div.identify{
                            font-size: 12px;
                            color: $font_color;
                            font-weight: 600;
                            padding-bottom: 10px;
                            text-transform: uppercase;
                            //@include anim;
                            p{
                                font-weight: inherit;
                            }
                        }
                    }

                }
            }
        }
    }
    div.events{
        .calendar{
            img{
                max-width: 100%;
                height: auto;
            }
            margin-bottom: 40px;
        }
        .section_header{
            margin-bottom: 20px;
        }
        .article_list{
            ul{
                li{
                    margin-bottom: 20px;
                    &:last-child{
                        margin-bottom: 0;
                    }
                    a{
                        text-decoration: none;
                        color: $white;
                    }
                    width: 100%;
                    article{
                        height: 200px;
                        width: 100%;
                        background-repeat: no-repeat;
                        background-position: center;
                        background-size: contain;
                        position: relative;
                        border-radius: 7px;
                        overflow: hidden;
                        @media only screen and (max-width: 991px) {
                            background-position: center top;
                        }
                        div.article_content{
                            z-index: 2;
                            width: 100%;
                            position: absolute;
                            left: 0;
                            bottom: 15px;
                            padding: 0 30px;
                            display: table;
                            @media only screen and (max-width: 991px) {
                                display: block;
                                text-align: center;
                            }
                            > div{
                                display: table-cell;
                                vertical-align: middle;
                                @media only screen and (max-width: 991px) {
                                    display: block;
                                    width: 100%;
                                }
                            }
                            div.default_font{
                                width: 45%;
                                @media only screen and (max-width: 991px) {
                                    width: 100%;
                                    margin-bottom: 15px;
                                }
                                h3{
                                    color: $white;
                                    font-weight: 700;
                                }
                            }
                        }
                        div.cover{
                            @include fill;
                            @include gradient_cover;
                        }
                    }
                }
            }
        }
    }
}

.classfields{
    .description{
        padding-left: 0 !important;
    }
}

section.boxes{
    padding-bottom: 40px;
    @media only screen and (max-width: 991px) {
        padding-bottom: 30px;
    }
    div.box_list{
        ul{
            border-radius: 10px;
            overflow: hidden;
            li{
                display: inline-block;
                vertical-align: top;
                width: 33.33%;
                height: 120px;
                position: relative;
                font-size: 18px;
                line-height: 28px;
                font-weight: 700;
                background-position: right bottom;
                background-repeat: no-repeat;
                @media only screen and (max-width: 991px) {
                    width: 100%;
                }
                div.desc{
                    padding: 0 30px;
                }
                em{
                    color: $white;
                }
                &.orange{
                    background-color: #ed703d;
                    background-image: url('./../img/btn_icon1.png');
                    div.desc{
                        color: $white;
                    }
                }
                &.blue{
                    background-color: #9ac0f4;
                    background-image: url('./../img/btn_icon2.png');
                    div.desc{
                        color: #395f92;
                    }
                }
                &.purple{
                    background-color: #776caf;
                    background-image: url('./../img/btn_icon3.png');
                    div.desc{
                        color: white;
                    }
                }
                p{
                    font-weight: 700;
                }
            }
        }
    }
}

header.section_header{
    @media only screen and (max-width: 991px) {
        text-align: center;
    }
    div.default_font{
        padding-top: 5px;
        @media only screen and (max-width: 991px) {
            margin-bottom: 15px;
        }
    }
    .number{
        padding-top: 40px;
        @media only screen and (max-width: 991px) {
            padding-top: 5px;
        }
    }
}

section.top{
    padding: 14px 0;
    background: #e6ecf1;
    @media only screen and (max-width: 991px) {
        display: none;
    }
    div.nav_way{
        > div{
            display: inline-block;
            vertical-align: top;
            &.default_font{
                margin-right: 20px;
                color: $font_color;
            }
            ul{
                li{
                    display: inline-block;
                    vertical-align: middle;
                    position:relative;
                    padding-right:20px;
                    margin-right: 20px;
                    a{
                        display:block;
                        font-size: 12.5px;
                        line-height: 20px;
                        text-decoration: none;
                        color: $font_color;
                        font-weight: 600;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        white-space: nowrap;
                        max-width: 220px;
                    }
                    &:after{
                        position:absolute;
                        content: '/';
                        padding-left: 0px;
                        margin-right: 0px;
                        top:4px;
                        right:0;
                    }
                    .on{
                        a{
                            font-weight: 800;
                            color: $header_color;
                        }
                    }

                    &:last-child{
                        &:after{
                            display: none;
                        }
                    }
                }
            }
        }
    }
}

section.news_details{
    .section_header{
        margin-bottom: 40px;
    }
    div{
        &.details_image{
            height: 380px;
            width: 100%;
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            //margin-bottom: 40px;
        }
        &.desc{
            padding-bottom: 40px;
            margin-bottom: 40px;
            border-bottom: 1px solid #dce0e5;
        }
    }
}

section.download, section.galery{
    margin-bottom: 40px;
    padding-bottom: 40px;
    border-bottom: 1px solid #dce0e5;
    header{
        color: $header_color;
        margin-bottom: 40px;
        @media only screen and (max-width: 991px) {
            text-align: center;
            margin-bottom: 50px;
        }
        h3{
            font-size: 20px;
            line-height: 30px;
            font-weight: 800;
        }
    }
    div.files{
        ul{
            li{
                margin-bottom: 20px;
                @media only screen and (max-width: 991px) {
                    margin-bottom: 50px;
                }
                &:last-child{
                    margin-bottom: 0;
                }
                a{
                    display: inline-block;
                    min-height: 35px;
                    padding-left: 50px;
                    position: relative;
                    font-size: 12.5px;
                    line-height: 12.5px;
                    color: $font_color;
                    font-weight: 600;
                    background-color: $white;
                    padding-top: 10px;
                    padding-right: 20px;
                    @include anim;
                    z-index: 1;
                    @media only screen and (max-width: 991px) {
                        width: 100%;
                        min-height: 50px;
                        display: block;
                        padding: 15px;
                        text-align: center;
                        line-height: 15px
                    }
                    &:hover{
                        color: $white;
                        @media only screen and (max-width: 991px) {
                            color: #6c7684;
                        }
                        span{
                            color: $white;
                            border-bottom: 1px solid $white;
                            @media only screen and (max-width: 991px) {
                                color: #6c7684;
                                border-bottom: 1px solid #6c7684;
                            }
                        }
                        &:after{
                            width: 100%;
                        }
                    }
                    span{
                        border-bottom: 1px solid $font_color;
                        //@include anim;
                        font-weight: 800;
                    }
                    &:after{
                        z-index: -1;
                        @include anim;
                        content:'';
                        position: absolute;
                        top: 0;
                        left: 0;
                        height: 35px;
                        width: 35px;
                        background-color: $accent_color;
                        background-repeat: no-repeat;
                        background-position: left 13px center;
                        background-image: url('/img/download_arr.png');
                        @media only screen and (max-width: 991px) {
                            width: 100%;
                            top: auto;
                            bottom: 100%;
                            background-position: center center;
                        }
                    }
                }

                &.spec_view a {
                    color:#fff;
                    background-color: $accent_color;
                    font-size:16px;
                    text-decoration: none;

                    span {
                        border-bottom:0px;
                    }

                    &:after {
                        //background-color:#fff;
                        //color:$accent_color;
                    }
                }
            }
        }
    }
    .show_more{
        margin-top: 15px;
        text-decoration: underline;
        cursor: pointer;
        span{
            @include anim;
        }
    }
    .hidden_desc{
        padding-top: 15px;
        border-bottom: 1px solid #dce0e5;
        padding-bottom: 15px;
        display: none;
        table{
            width: 80%;
            font-size: 12.5px;
            color: $font_color;
            @media only screen and (max-width: 991px) {
                font-size: 10px;
                width: 100%;
            }
            strong{
                font-weight: 800;
            }
            tr{
                td{
                    padding: 12.5px 0;
                    &:first-child{
                        width: 50%;
                    }
                    &:last-child{
                        text-align: right;
                    }
                }
            }
        }
    }
}

section.galery{
    figure.image{
        width: 100%;
        height: 180px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        position: relative;
        &:hover{
            .vertical_align{
                opacity: 1;
                visibility: visible;
            }
        }
        .vertical_align{
            text-align: center;
            @include anim;
            background: rgba(59,132,222,0.5);
            visibility: hidden;
            opacity: 0;
        }
        @media only screen and (max-width: 991px) {
            height: 300px;
            margin-bottom: 15px;
        }
    }
}

section.summary{
    background: #e6ecf1;
    div{
        &.summary_top{
            padding: 30px;
            border-bottom: 1px solid #d1dbe3;
            @media only screen and (max-width: 991px) {
                text-align: center;
            }
            .buttons{
                text-align: right;
                @media only screen and (max-width: 991px) {
                    text-align: center;
                    padding-top: 30px;
                }
                > li{
                    display: inline-block;
                    vertical-align: top;
                    margin-right: 15px;
                    @media only screen and (max-width: 991px) {
                        margin-right: 0;
                        margin-bottom: 15px;
                        display: block;
                        &:last-child{
                            margin-bottom: 0;
                        }
                    }
                    &:last-child{
                        margin-right: 0;
                    }
                    a{
                        font-size: 12.5px;
                        line-height: 12.5px;
                        display: inline-block;
                        padding: 13px 63px 13px 26px;
                        background-repeat: no-repeat;
                        background-position: center right 10px;
                        color: $font_color;
                        background-color: $white;
                        border-radius: 7px;
                        font-weight: 700;
                        &.print{
                            background-image:url('./../img/print_icon.png');
                        }
                        &.pdf{
                            background-image: url('./../img/doc_icon.png');
                        }
                    }
                }
            }
        }
        &.summary_bottom{
            padding:15px 30px;
            @media only screen and (max-width: 991px) {
                text-align: center;
            }
            div.default_font{
                display: inline-block;
                vertical-align: top;
                margin-right: 15px;
                .show{
                    text-decoration: underline;
                    cursor: pointer;
                }
                &:last-child{
                    margin-right: 0;
                }
            }
        }
        &.hidden_changes{
            padding: 0px 30px 15px 30px;
            display: none;
            ul{
                li{
                    display: inline-block;
                    vertical-align: top;
                    width: 32%;
                    margin-right: 2%;
                    position: relative;
                    @media only screen and (max-width: 991px) {
                        margin-right: 0;
                        width: 100%;
                        margin-bottom: 15px;
                    }
                    &:after{
                        content: '';
                        width: 6%;
                        height: 100%;
                        background-size: 50% auto;
                        background-position: center;
                        background-repeat: no-repeat;
                        background-image: url('./../img/change_arr.png');
                        position: absolute;
                        top: 0;
                        left: 100%;
                        @media only screen and (max-width: 991px) {
                            display: none;
                        }
                    }
                    &:nth-child(3n), &:last-child{
                        margin-right: 0;
                        &:after{
                            display: none;
                        }
                    }
                    .change_table{
                        width: 100%;
                        background: $white;
                        color: $font_color;
                        font-size: 12.5px;
                        line-height: 12.5px;
                        font-weight: 800;
                        border: 1px solid #d1dbe3;
                        margin-bottom: 10px;
                        p{
                            font-weight: inherit;
                        }
                        tr{
                            border-bottom: 1px solid #d1dbe3;
                            td{
                                padding: 15px;
                                border-right: 1px solid #d1dbe3;
                                &:first-child{
                                    width: 30%;
                                }
                                &:last-child{
                                    border-right: none;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

aside.side_menu{
    @media only screen and (max-width: 991px) {
        margin-bottom: 15px;
    }
    div.box_menu{
        header{
            text-align: center;
            font-weight: 700;
            color: $white;
            background: $accent_color;
            padding: 12px 0;
            h2{
                font-size:16px;
                line-height: 16px;
            }
        }
        nav.box_menu_list{
            background: $white;
            ul{
                li{
                    > ul{
                        display: none;
                        > li{
                            &:first-child{
                                border-top: 1px solid #e6ecf1;
                            }
                            &:last-child{
                                border-bottom: none;
                            }
                            > a{
                                padding-left: 30px;
                            }
                        }
                    }
                    border-bottom: 1px solid #e6ecf1;
                    a,p{
                        display: block;
                        padding: 15px 55px 15px 20px;
                        text-transform: uppercase;
                        font-size: 12.5px;
                        line-height: 16.5px;
                        color: $font_color;
                        @include anim;
                        font-weight: 600;
                        @media only screen and (max-width: 1200px) {
                            font-size: 11px;
                            line-height: 11px;
                        }
                        &:hover{
                            background-color:#e6ecf1;
                        }
                        &.on{
                            background-color:#e6ecf1;
                        }
                    }
                    p{
                        @include anim;
                        background-repeat: no-repeat;
                        background-position: right 20px top 15px;
                        background-image: url('./../img/plus.png');
                        &.open{
                            background-image: url('./../img/minus.png');
                            background-color:#e6ecf1;
                            background-position: right 20px top 19px;
                        }
                        cursor: pointer;
                        position: relative;
                    }
                }
            }
        }
    }
}

section.activity, section.bip{
    div.image{
        position: relative;
        height: 420px;
        width: 100%;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        margin-bottom: 5px;
        font-size: 18px;
        line-height: 28px;
        color: $white;
        text-align: center;
        @media only screen and (max-width: 991px) {
            height: 300px;
            margin-bottom: 20px;
            margin-top: 30px;
        }
        p{
            padding-top: 15px;
        }
        div.default_font{
            h1{
                font-size: 37px;
                @media only screen and (max-width: 1200px) {
                    font-size: 30px;
                }
                strong{
                    color: $white;
                }
            }
            h4{
                strong{
                    color: $white;
                }
            }
        }
    }
    div.desc{
        @media only screen and (max-width: 991px) {
            text-align: center;
        }
        header{
            margin-bottom: 15px;
            h2{
                font-size: 18px;
                font-weight: 700;
            }
        }
    }
}

.classfields_details{
    .section_header{
        padding-bottom: 40px;
        margin-bottom: 40px;
        border-bottom: 1px solid #dce0e5;
        @media only screen and (max-width: 991px) {
            padding-bottom: 20px;
            margin-bottom: 20px;
        }
    }
}

section.contact{
    .section_header{
        margin-bottom: 40px;
    }
    div.desc{
        div.default_font{
            margin-bottom: 40px;
            @media only screen and (max-width: 991px) {
                text-align: center;
                margin-bottom: 20px;
            }
        }
    }
    div.map_box{
        height: 378px;
        width: 100%;
        position: relative;
        margin-bottom: 40px;
        > iframe{
            @include fill;
            border: none;
        }
    }
}

.text-right{
    @media only screen and (max-width: 991px) {
        text-align: center;
    }
}

.default_calendar{
    -webkit-box-shadow: 0px 0px 74px 0px rgba(143,150,161,0.3);
    -moz-box-shadow: 0px 0px 74px 0px rgba(143,150,161,0.3);
    box-shadow: 0px 0px 74px 0px rgba(143,150,161,0.3);
    width: 100%;
    div{
        &.header{
            background: $accent_color;
            color: $white;
            display: table;
            height: 77px;
            width: 100%;
            > div{
                display: table-cell;
                vertical-align: middle;
                height: 100%;
                text-align: center;
                &.left, &.right{
                    span{
                        height: 15px;
                        width: 10px;
                        background-repeat: no-repeat;
                        background-position: center;
                        &:before{
                            display: none;
                        }
                    }
                }
                &.left{
                    width: 25%;
                    span{
                        background-image: url('./../img/cal_arr_lefft.png');
                        cursor: pointer;
                    }
                }
                &.center{
                    width: 50%;
                    h3{
                        font-size: 16px;
                        font-weight: 700;
                    }
                }
                &.right{
                    width: 25%;
                    span{
                        background-image: url('./../img/cal_arr_right.png');
                        cursor: pointer;
                    }
                }
            }
        }
        &.content{
            border-bottom: 2px solid #dde5f1;
            div.table{
                padding:25px 40px 0 40px;
                @media only screen and (max-width: 991px) {
                    padding: 25px 25px 0 25px;
                }
                > div{
                    width: 100%;
                    display: table;
                    height: 45px;
                    @media only screen and (max-width: 1200px) {
                        height: 33px;
                    }
                    &.tr{
                        &:first-child{
                            margin-bottom: 15px;
                            height: auto;
                            > div{
                                font-weight: 700;
                                color: $header_color;
                            }
                        }
                    }
                    > div{
                        display: table-cell;
                        vertical-align: middle;
                        width: 10%;
                        text-align: center;
                        font-size: 14px;
                        line-height: 14px;
                        color: $font_color;
                        font-weight: 600;
                        border-radius: 15px;
                        &.td{
                            &.day_event{
                                background-color: $accent_color;
                                color: $white;
                                @media only screen and (max-width: 991px) {
                                    color: $accent_color;
                                    background: transparent;
                                }
                            }
                            &.day_event_past{
                                background-color: $accent_color_gray;
                                color: $white;
                                @media only screen and (max-width: 991px) {
                                    color: $accent_color;
                                    background: transparent;
                                }
                            }
                            span{
                                &.content{
                                    color: $white;
                                    background: $accent_color;
                                    display: block;
                                    font-weight: 700;
                                    font-size: 13px;
                                    padding: 15px;
                                }
                            }
                            .calendar_tool{
                                padding: 15px;
                                h2{
                                    font-size: 20px;
                                    line-height: 30px;
                                    color: $font_color;
                                    a{
                                        font-size: 13px;
                                        color: $accent_color;
                                        display: inline-block;
                                        font-weight: 600;
                                    }
                                }
                            }
                            position: relative;
                            .cal_list{
                                -webkit-box-shadow: 0px 0px 74px 0px rgba(143,150,161,0.3);
                                -moz-box-shadow: 0px 0px 74px 0px rgba(143,150,161,0.3);
                                box-shadow: 0px 0px 74px 0px rgba(143,150,161,0.3);
                                position: absolute;
                                top: 110%;
                                right: -100%;
                                z-index:10;
                                width: 200px;
                                @media only screen and (max-width: 991px) {
                                    right: 0;
                                }
                                > li{
                                    border: 1px solid $accent_color;
                                    background: $white;
                                    &.event{
                                        display:none;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.pagination {
    display: inline-block;
    //padding-left: 30%;
    width:100%;
    text-align:center;
}

.pagination a, .pagination p {
    color: black;
    //float: left;
    padding: 8px 16px;
    text-decoration: none;
    transition: background-color .3s;
    border: 1px solid #ddd;
    background-color: #ffffff;
    font-size:12px;
    display: inline-block;
    vertical-align: top;
}

.pagination a.active {
    background-color: #de3b46;
    color: white;
    border: 1px solid #de3b46;
}

.pagination a:hover:not(.active) {background-color: #ddd;}

.error_content{
    padding: 50px 15px;
    font-size:30px; 
    text-align: center;
    font-weight: 700;
    p{
        font-weight: inherit;
    }
    img{
        margin-bottom: 50px;
        max-width: 100%;
        height: auto;
    }
}

.seach_list1{
    > li{
        color: #7e8894; 
        font-size: 12px;
        .lp{
           width: 70px;
           font-size: 41px;
            line-height: 40px;
            color: $accent_color;
            padding-top: 3px;
            text-align: center;
        }
        .description{
            width: calc(100% - 70px) !important;
            padding-left: 15px !important;
        }
        &:hover{
            .lp{
                color: $white !important;
            }
            .description{
                color: $white !important;
            }
        }
    }
}


.front_pagginate{
    text-align: center;
    > ul{
        > li{
            display: inline-block;
            vertical-align: top; 
        }
    }
    ul{
        li{
        
        &.prev, &.next{
            color: black;
                width: 41px;
                height: 33px;
                text-decoration: none;
                transition: background-color .3s;
                border: 1px solid #ddd;
                background-color: #ffffff;
                font-size: 12px;
                line-height: 28px;
                text-align: center;
                cursor: pointer;
                &:hover{
                    background-color: #ddd;
                }
        }
        > ul{
            > li{
                display: inline-block;
                vertical-align: top;
                color: black;
                width: 41px;
                height: 33px;
                text-decoration: none;
                transition: background-color .3s;
                border: 1px solid #ddd;
                background-color: #ffffff;
                font-size: 12px;
                line-height: 28px;
                text-align: center;
                &:hover{
                    background-color: #ddd;
                }
                &:last-child{
                    margin-right: 0;
                }
                &.active{
                    border: 1px solid $accent_color;
                    background: $accent_color;
                    a{
                        color: $white !important;
                    }
                }
                a{
                    
                    display: block;
                    padding: 10px 0;
                    color: $header_color;
                    font-size: 12px;
                    line-height: 12px;
                    font-weight: 600;
                }
            }
        }
       }
   }
}

.numberr, .identify_no, .skrytka_podawcza{
    color: #172436;
    font-weight: 800;
    font-size: 20px;
    line-height: 30px;
    font-weight: bold;
    a{
        color: $accent_color;
    }
    p,a{
        font-weight: inherit;
    }

}

.classfields_details {
    .section_header{
        &.header2{
            > .default_font{
                padding-top: 0;
                margin-bottom: 20px;
            }
        }
        
    }
}

div.bip_search{
    padding:22px 30px;
    background: $white;
    p{
        font-size: 13.5px;
        font-weight: 700;
        margin-bottom: 10px;
    }
    .input{
        outline: none;
        width: 100%;
        color: #6c7684;
        font-size: 12px;
        font-weight: 600;
        padding: 0 20px;
        background: #e6ecf1;
        height: 45px;
        border: none;
        margin-bottom: 20px;
    }
    .submit{
        width: 200px;
        outline: none;
        font-weight: 700;
        font-size: 12.5px;
        color: #ffffff;
        padding: 13px 31px;
        border-radius: 10px;
        background: #de3b46;
        display: inline-block;
        transition: 0.3s all ease;
        text-decoration: none;
        border: none;
        @include anim;
        border: 1px solid $accent_color;
        &:hover{
            color: $accent_color;
            background: $white;
        }
    }
}

.chosen-container{
    margin-bottom: 20px;
    width: 100% !important;
    &.chosen-container-single{
        .chosen-default{
            color: $font_color;
        }
    }
    .chosen-drop{
        background: #e6ecf1;
        border: none;
    }
    .chosen-results{
        color: $font_color;
        li{
            text-align: left;
            font-weight: 600;
            font-size: 12.5px;
            &.highlighted{
                background: $accent_color;
            }
            &.no-results{
                background: $accent_color;
                color: $white;
            }
        }
    }
    .chosen-single{
        height: 45px;
        padding-left: 19px;
        background: #e6ecf1;
        box-shadow: none;
        border: none;
        border-radius: 0;
        span{
            text-align: left;
            padding-top: 10px;
            color: $font_color;
        }
        div{
            width: 50px;
            b{
                background-position: center;
                background-image: url('./../img/select_arr.png');
            }
        }
    }
}
.image_desc{
    text-align: center;
    margin: 5px 0;
    font-size: 12.5px;
    font-weight: 600;
}

.details_image{
    position: relative;
    &:hover{
        .vertical_align{
            opacity: 1;
            visibility: visible;
        }
    }
    .vertical_align{
        text-align: center;
        @include anim;
        background: rgba(59,132,222,0.5);
        visibility: hidden;
        opacity: 0;
    }
}

.logo_boxes{
    padding-top: 2.5%;
    @media only screen and (max-width: 991px) {
        padding-top:0px;
    }
    div.banner_box{
        width: 100%;
        height: 0;
        padding-bottom: 13.2%;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
    }
    div.logotypes_list{
        padding-top: 4%;
        @media only screen and (max-width: 991px) {
            padding-top:10px;
        }
        > ul{
            > li{
                width: 22%;
                display: inline-block;
                vertical-align: top;
                overflow: hidden;
                margin-right: 4%;
                margin-bottom: 4%;
                @media only screen and (max-width: 991px) {
                    width:100%;
                    margin-right:0;
                    margin-bottom:10px;
                }
                &:nth-child(4n), &:last-child{
                    margin-right: 0;
                }
                &:hover{
                    div.image{
                        transform: scale(1.15);
                    }
                }
                div.image{
                    width: 100%;
                    height: 0;
                    padding-bottom: 60%;
                    background-position: center;
                    background-size: 50% auto;
                    background-repeat: no-repeat;
                    background-color: $white;
                    @include anim;
                    @media only screen and (max-width: 991px) {
                        padding-bottom:13.2%;
                        background-size: 20% auto;
                    }
                }
            }
        }
    }
}